import React from "react"
import "./Experts.scss"


export default ({ children }) => {
  // return <div className="box-n">{children}</div>
  return (

    <div>

      <div className="block-header-left " style={{marginBottom:"48px"}}>
        <h3 style={{paddingBottom:"8px", marginBottom:"0px"}}>Ekspertų taryba</h3>
        <p>Susipažink su egzamino Ekspertų taryba! <br/>
          Šie savo sričių specialistai rengia egzamino klausimus ir ruošia edukacinį turinį.</p>
      </div>


      <div className="cards-list">

        <div className="card-exp">
          <div className="card_image"><img src="https://aplinkosaugosegzaminas.lrt.lt/ekspertai/Ieva-Budraite.jpg"/></div>
          <div className="card_title title-white">
            <p>Budraitė <br/>Ieva</p>
            <p style={{fontWeight: "normal"}}>Žaliosios politikos instituto analitikė</p>
          </div>
        </div>

        <div className="card-exp">
          <div className="card_image"><img src="https://aplinkosaugosegzaminas.lrt.lt/ekspertai/Vaida-Griskeviciene.jpg"/></div>
          <div className="card_title title-white">
            <p>Griškevičienė<br/> Vaida</p>
            <p style={{fontWeight: "normal"}}>„Žaliojo taško“ atstovė, aplinkosaugininkė</p>
          </div>
        </div>

        <div className="card-exp">
          <div className="card_image"><img src="https://aplinkosaugosegzaminas.lrt.lt/ekspertai/Meda-Sermuksne2.jpg"/></div>
          <div className="card_title title-white">
            <p>Šermukšnė <br/>Meda</p>
            <p style={{fontWeight: "normal"}}>asociacijos „Gyvi gali” vadovė</p>
          </div>
        </div>

        <div className="card-exp">
          <div className="card_image"><img src="https://aplinkosaugosegzaminas.lrt.lt/ekspertai/Laura-Stukonyte.jpg"/></div>
          <div className="card_title title-white">
            <p>Stukonytė<br/> Laura</p>
            <p style={{fontWeight: "normal"}}>jūrų biologė, „Po bangom“ bendraįkūrėja</p>
          </div>
        </div>

        <div className="card-exp">
          <div className="card_image"><img src="https://aplinkosaugosegzaminas.lrt.lt/ekspertai/Ieva-Kazakeviciute.jpg"/></div>
          <div className="card_title title-white">
            <p>Kazakevičiūtė <br/>Ieva</p>
            <p style={{fontWeight: "normal"}}>„Sustain Academy“ bendraįkūrėja</p>
          </div>
        </div>

        <div className="card-exp">
          <div className="card_image"><img src="https://aplinkosaugosegzaminas.lrt.lt/ekspertai/Remigijus-Lapinskas.jpg"/></div>
          <div className="card_title title-white">
            <p>Lapinskas<br/> Remigijus</p>
            <p style={{fontWeight: "normal"}}>Žaliosios politikos instituto prezidentas</p>
          </div>
        </div>


        <div className="card-exp">
          <div className="card_image"><img src="https://aplinkosaugosegzaminas.lrt.lt/ekspertai/Ignas-Kancys.jpg"/></div>
          <div className="card_title title-white">
            <p>Kančys<br/> Ignas</p>
            <p style={{fontWeight: "normal"}}>„Mokslo sriuba” redaktorius</p>
          </div>
        </div>

        <div className="card-exp">
          <div className="card_image"><img src="https://aplinkosaugosegzaminas.lrt.lt/ekspertai/Lina-Rucinskiene.jpg"/></div>
          <div className="card_title title-white">
            <p>Ručinskienė<br/> Lina</p>
            <p style={{fontWeight: "normal"}}>aplinkos duomenų analitikė</p>
          </div>
        </div>

        <div className="card-exp">
          <div className="card_image"><img src="https://aplinkosaugosegzaminas.lrt.lt/ekspertai/Ieva-Sadauskaite.jpg"/></div>
          <div className="card_title title-white">
            <p>Sadauskaitė<br/> Ieva</p>
            <p style={{fontWeight: "normal"}}>LRT aplinkosaugos specialistė</p>
          </div>
        </div>

        <div className="card-exp">
          <div className="card_image"><img src="https://aplinkosaugosegzaminas.lrt.lt/ekspertai/Shayan-Ali.jpg"/></div>
          <div className="card_title title-white">
            <p>Shayan<br/> Ali</p>
            <p style={{fontWeight: "normal"}}>„Continental Autonomous Mobility“ vadovas</p>
          </div>
        </div>

        <div className="card-exp">
          <div className="card_image"><img src="https://aplinkosaugosegzaminas.lrt.lt/ekspertai/Ugne-Kumparskaite.jpg"/></div>
          <div className="card_title title-white">
            <p>Kumparskaitė<br/> Ugnė</p>
            <p style={{fontWeight: "normal"}}>Vystomojo bendradarbiavimo platformos vykdančioji direktorė</p>
          </div>
        </div>

        <div className="card-exp">
          <div className="card_image"><img src="https://aplinkosaugosegzaminas.lrt.lt/ekspertai/Rasa-Zubrickiene.jpg"/></div>
          <div className="card_title title-white">
            <p>Zubrickienė<br/> Rasa</p>
            <p style={{fontWeight: "normal"}}>biologijos mokytoja-ekspertė</p>
          </div>
        </div>

        <div className="card-exp">
          <div className="card_image"><img src="https://aplinkosaugosegzaminas.lrt.lt/ekspertai/Asta-Burbaite.jpg"/></div>
          <div className="card_title title-white">
            <p>Burbaitė<br/> Asta</p>
            <p style={{fontWeight: "normal"}}>VšĮ „Žaliasis taškas“ atstovė</p>
          </div>
        </div>







      </div>

    </div>

  )
}
