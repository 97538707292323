import React from "react"
import "./Categories.scss"

import l_pradedantysis from "../../assets/svg/pradedantysis.svg"
import l_tyrinetojas from "../../assets/svg/tyrinetojas.svg"
import l_sumanus from "../../assets/svg/sumanus.svg"
import l_ekspertas from "../../assets/svg/ekspertas.svg"

export default ({ children }) => {
  // return <div className="box-n">{children}</div>
  return (
    <div>
      <div className="block-header">
        <h3>Dalyvių kategorijos</h3>
        <p>Dalyvavimas egzamine atviras ir nemokamas.
          Egzamino klausimai pagal sudėtingumą suskirstyti į keturias kategorijas. Jei esi moksleivis, kviečiame registruotis į „Pradedančiojo” ar „Smalsuolio” kategoriją. Jei giliai domiesi aplinkosauga ir esi pasiruošęs sudėtingiausiems klausimams, registruokis į „Ekspertų” kategoriją. Jei savęs nepriskiri nei vienai iš paminėtų grupių  – esi „Tyrinėtojas”, išbandyk savo jėgas čia!
        </p>
      </div>
  <div className="viewport">
    <ul className="list">

      <li className="item">
        <div className="itemImage">
          <img width="201px" src={l_pradedantysis} alt="Pradedantysis"/>
        </div>
        <div className="itemText">
          <h5>Pradedantysis</h5>
          <p>
            Jeigu esi <b>7–9 klasių moksleivis</b> – ši kategorija skirta tau.
          </p>
        </div>
      </li>

      <li className="item">
        <div className="itemImage">
          <img width="201px" src={l_tyrinetojas} alt="Tyrinėtojas"/>
        </div>
        <div className="itemText">
          <h5>Smalsuolis</h5>
          <p>
            Jeigu esi <b>10–12 kl. moksleivis</b>, rinkis šią kategoriją.
          </p>
        </div>
      </li>
      <li className="item">
        <div className="itemImage">
          <img width="201px" src={l_sumanus} alt="Sumanus"/>
        </div>
        <div className="itemText">
          <h5>Tyrinėtojas</h5>
          <p>
            Nuo studento iki senjoro – ši kategorija skirta <b>plačiajai visuomenei</b>.
          </p>
        </div>
      </li>
      <li className="item">
        <div className="itemImage">
          <img width="201px" src={l_ekspertas} alt="Ekspertas"/>
        </div>
        <div className="itemText">
          <h5>Ekspertas</h5>
          <p>
            Kategorija skirta <b>aktyviai aplinkosauga besidomintiems</b> ar šioje srityje dirbantiems specialistams.
          </p>
        </div>
      </li>
    </ul>
  </div>

    </div>
)
}
