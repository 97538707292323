import React from "react"
import "./EducationSmall.scss"

import { RaisedButton, CleanButton, GhostButton } from "../Buttons"

import ArrowRight from "../../assets/svg/right-arrow.svg"
import Environment from "../../assets/svg/environment.svg"


export default ({ children }) => {
  // return <div className="box-n">{children}</div>
  return (

    <div>

    <div className="block-header-left " style={{marginBottom:"48px"}}>
      <h3 style={{paddingBottom:"8px", marginBottom:"0px"}}>Pasiruošk</h3>
      <p>Čia rasi naudingos informacijos, kuri padės gilinti žinias apie mus supančią aplinką ir geriau pasiruošti egzaminui. </p>
    </div>

    <section class="main--container2">

      <a target="_blank"
         href={"https://www.lrt.lt/naujienos/gyvenimas/13/2106460/nacionalinis-aplinkosaugos-egzaminas-jau-si-antradieni"}>
        <article className="card">
          <img src="https://www.lrt.lt/img/2023/10/23/1612577-700537-393x221.jpg" alt=""/>
          <article className="content">
            <h6>Nacionalinis aplinkosaugos egzaminas – jau šį antradienį</h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"https://www.lrt.lt/naujienos/gyvenimas/13/2103873/16-metu-mesos-nevalganti-meda-lietuvoje-truksta-suvokimo-kokia-itaka-klimatui-daro-musu-mityba"}>
        <article className="card">
          <img src="https://www.lrt.lt/img/2023/08/17/1569588-190400-393x221.jpg" alt=""/>
          <article className="content">
            <h6>16 metų mėsos nevalganti Meda: Lietuvoje trūksta suvokimo, kokią įtaką klimatui daro mūsų mityba</h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"https://www.lrt.lt/naujienos/lietuvoje/2/2101480/kai-siuksliavezis-nuvaziuoja-kaip-toliau-rusiuojamos-atliekos-kai-jus-savo-darba-jau-padarete"}>
        <article className="card">
          <img src="https://www.lrt.lt/img/2023/09/28/1596965-562108-393x221.jpg" alt=""/>
          <article className="content">
            <h6>Kai šiukšliavežis nuvažiuoja: kaip toliau rūšiuojamos atliekos, kai jūs savo darbą jau padarėte</h6>
            <p>
            </p>
          </article>
        </article>
      </a>



    </section>

      <GhostButton href="https://aplinkosaugosegzaminas.lrt.lt/education" >
        Žiūrėti viską
        <img src={ArrowRight} alt="" style={{width : "24px", height : "24px"}}/>
      </GhostButton>

    </div>
  )
}
