import React from "react"
import Countdown, { zeroPad } from "react-countdown"
import { Link } from "gatsby"
import "@fontsource/dm-sans";
import Layout from "../Components/Layout"
import Categories from "../Components/Categories"
import EducationSmall from "../Components/EducationSmall"

import Experts from "../Components/Experts"

import isEnded from "../utils/isEnded"
import { RaisedButton, CleanButton, GhostButton } from "../Components/Buttons"
import PreFooterImg from "../assets/images/home-pre-footer.jpg"
import Environment from "../assets/svg/environment.svg"
import Ecosystem from "../assets/svg/ecosystem.svg"
import World from "../assets/svg/world.svg"
import Logo from "../assets/svg/logo.jpg"
{/*
import LrtLogo from "../assets/svg/lrt-logo.svg"
import LrtLogoDark from "../assets/svg/lrt-logo-dark.svg"

import MmoLogo from "../assets/svg/mmo-logo.svg"
import MmoLogoDark from "../assets/svg/mmo-logo-dark.svg"
*/}

import LrtLogo from "../assets/svg/lrt-wh.svg"
import LrtLogoDark from "../assets/svg/lrt-wh.svg"

import MmoLogo from "../assets/svg/Group 36.svg"
import MmoLogoDark from "../assets/svg/Group 36.svg"

import ArrowRight from "../assets/svg/arrow-right.svg"
import MmoPhoto from "../assets/images/mmo-photo.jpg"
import Deco1 from "../assets/images/deco-1.png"
import Deco2 from "../assets/images/deco-2.png"

import Place1 from "../assets/images/places/place-1.png"
import Place2 from "../assets/images/places/place-2.png"
import Place3 from "../assets/images/places/place-3.png"
import Place4 from "../assets/images/places/place-4.png"
import Place5 from "../assets/images/places/place-5.png"
import Place6 from "../assets/images/places/place-6.png"

const PLACES = [
  {
    title: "1 vieta",
    text:(<p>Išskirtinė ekskursija MO saugykloje tau ir 10 tavo svečių + 2 MOdernisto kortelės</p>),
    image: Place1,
  },
  {
    title: "2 vieta",
    text: (<p>Dėmesingo įsisąmoninimo (<i>mindfulness</i>) užsiėmimas MO muziejuje tau ir 10 tavo svečių + 2 MOdernisto kortelės</p>),
    image: Place2,
  },
  {
    title: "3 vieta",
    text: (<p>Privatus 33 minučių patyrimo seansas tau ir 10 tavo svečių + 2 MOdernisto kortelės</p>),
    image: Place3,
  }
]

const PLACESM = [
  {
    title: "1 vieta",
    text:(<p>Naujausia MO edukacija Vilniaus mieste apie grafičio meną tau ir tavo klasės draugams</p>),
    image: Place4,
  },
  {
    title: "2 vieta",
    text: (<p>Riboto leidimo marškinėliai su <a href="https://mo.lt/modiseja-2021-marskineliai/">Žygimanto Augustino kūriniu</a> + 50 € vertės MO parduotuvės dovanų kuponas</p>),
    image: Place5,
  },
  {
    title: "3 vieta",
    text: (<p>Naktį šviečiantis krepšys su <a href="https://mo.lt/modiseja-2021-krepsys/">MO iliustracija</a> + 25 € vertės MO parduotuvės dovanų kuponas</p>),
    image: Place6,
  }
]




export default () => (
  <Layout>
    <div className="grid m-reverse" >

      <div className="grid--cell w-50 ">


        {/*<div className="index--countdown">*/}
        {/*  <div className="index--countdown-info">*/}
        {/*    <div>Projektą organizuoja:</div>*/}
        {/*    <div className="index--countdown-info-logos">*/}
        {/*      <a*/}
        {/*        href="https://www.llri.lt/"*/}
        {/*        target="_blank"*/}
        {/*        rel="noopener noreferrer"*/}
        {/*        title="LLRI"*/}
        {/*      >*/}
        {/*        <img height="40px" src={MmoLogo} alt="MMO" />*/}
        {/*      </a>*/}
        {/*    </div>*/}
        {/*  </div>*/}


        {/*  <div className="index--countdown-completed">*/}
        {/*    <h1 className="ended">*/}
        {/*      EKONOMIKOS*/}
        {/*      <br/>*/}
        {/*      VIKTORINA*/}
        {/*    </h1>*/}
        {/*    <RaisedButton variant="white" to="/registration">*/}
        {/*      Registruotis*/}
        {/*    </RaisedButton>*/}

        {/*    <small>Jau užsiregistravai?</small>*/}

        {/*    <GhostButton to="/start">SPAUSK ČIA</GhostButton>*/}
        {/*  </div>*/}

        <div>
          <div className="index--about-project">
            <div className="text-container">
              {/*<h2>Iki Nacionalinio aplinkosaugos egzamino
                liko 0 dienų</h2>*/}

              <h3>  Spalio 24 d. paminėk Tarptautinę klimato kaitos dieną pasitikrindamas žinias Nacionaliniame aplinkosaugos egzamine! </h3>
              <p> </p>

              <RaisedButton href="https://aplinkosaugosegzaminas.lrt.lt/exam-start" >Pasitikrink savo atsakymus</RaisedButton>


              {/*<br/>


 <RaisedButton href="https://aplinkosaugosegzaminas.lrt.lt/registration" >Registruotis egzaminui</RaisedButton>
              <br/> <br/>
              <RaisedButton href="https://aplinkosaugosegzaminas.lrt.lt/exam-start" >Pradėti egzaminą</RaisedButton>

              <br/>
              <RaisedButton href="https://aplinkosaugosegzaminas.lrt.lt/registration" >Registruotis egzaminui</RaisedButton>
              */}

            </div>
          </div>
        </div>


        {/*</div>*/}
      </div>



      <div className="grid--cell w-50 index--logo mm-height">
        {/*<div className="index--logo-info">*/}
        {/*  <div>Kviečia dalyvauti</div>*/}
        {/*  <div className="index--logo-info-logos">*/}
        {/*    <a*/}
        {/*      href="https://lrt.lt"*/}
        {/*      target="_blank"*/}
        {/*      rel="noopener noreferrer"*/}
        {/*      title="LRT"*/}
        {/*    >*/}
        {/*      <img height="40px" src={LrtLogoDark} alt="LRT" />*/}
        {/*    </a>*/}
        {/*    <a*/}
        {/*      href="https://www.llri.lt/"*/}
        {/*      target="_blank"*/}
        {/*      rel="noopener noreferrer"*/}
        {/*      title="LLRI"*/}
        {/*    >*/}
        {/*      <img height="40px" src={MmoLogoDark} alt="MMO" />*/}
        {/*    </a>*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/*/* <img className="first" src={Logo} alt="" />*/}
        {/*style={{backgroundImage : `url(${TopIlliustration})`}}*/}
        {/* <img src={TopIlliustration} alt="" />*/}
        {/* */}
        {/* <div className="img-h">*/}
        {/*   */}
        {/* </div>*/}

        <img src={Environment} alt="" style={{width : "100%"}}/>
      </div>






      {/* <div className="dn-mobile-up tac mt-10 mb-10">
        <img src={Deco1} alt="" />
      </div>*/}

    </div>



    {/*Kas kaip kada*/}
    <div className="grid">
      <div className="grid--cell w-50">
        <div>
          <div className="index--about-project">

            <img src={World}/>

          </div>
        </div>
      </div>

      <div className="grid--cell w-50">
        <div>
          <div className="index--about-project">
            <div className="text-container">
              <h2>Kur, kaip, kada?</h2>
              <h3>
                Egzaminas vyks internetu
                <br/>spalio 24 d.
                <br/>8.00–20.00 val.
              </h3>
              <p>
                Nacionalinis aplinkosaugos egzaminas – puiki galimybė pasitikrinti aplinkosaugos žinias ir įgyti naujų!<br/>
                Kviečiame dalyvauti 7–12 klasių moksleivius, plačiąją visuomenę ir aplinkosauginį išsilavinimą turinčius ekspertus.<br/>
                Egzaminą organizuoja Žaliosios politikos institutas.
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>


    {/*Kategorijos*/}
    <div className="grid">
      <div className="grid--cell">
        <div>

          <Categories></Categories>

        </div>
      </div>
    </div>

    {/*Prizai*/}
    <div className="grid" style={{paddingTop:20}}>
      <div className="grid--cell">
        <div>

          <div className="block-header">
            <h3>Prizai</h3>
            <p>
              Už puikias aplinkosaugos žinias - įspūdingi prizai! Planšetės, elektroninės skaityklės, laikrodžiai, pažintinių žurnalų prenumeratos, ekskursijos, knygos, kūno priežiūros gaminiai ir daug kitų tvarias patirtis dovanojančių prizų jau laukia. Užsiregistruok dalyvauti, gauk pasiruošti padėsiantį naujienlaiškį, laikyk egzaminą ir pasiimk pelnytas dovanas!
            </p>
          </div>

        </div>
      </div>
    </div>


    {/*Pasiruošk*/}
    <div className="grid">
      <div className="grid--cell">

          <EducationSmall></EducationSmall>


      </div>
    </div>


    {/*Ekspertų komisija*/}
    <div className="grid">
      <div className="grid--cell">

        <Experts></Experts>


      </div>
    </div>


    <div className="grid bg-black" style={{padding:0}}>
      <div className="grid--cell w-50 ">


        <div>
          <div className="index--about-project">
            <div className="text-container" style={{color:"white"}}>
              {/*<p>Iki egzamino liko 0 dienų</p>
              <h2 style={{color:"white"}}>Registruokis ir pradėk
                ruoštis egzaminui
                jau dabar!</h2>
              <RaisedButton href="https://aplinkosaugosegzaminas.lrt.lt/registration" >Registruotis</RaisedButton>
              */}
            </div>
          </div>
        </div>




      </div>

      <div className="grid--cell w-50 " style={{display:"flex", flexDirection:"column-reverse"}}>
        <img src={Ecosystem} alt="" style={{width : "100%", marginBottom: 0}}/>
      </div>
    </div>



  </Layout>
)
